.Analytics{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.analytics-iframe{
    /* width: 35vw; */
    margin-bottom: 3vh;
    /* height: 300px */
    height: 85vh;
}


@media (max-width: 900px) { 
    .analytics-iframe{
        width: 67vw;
    }

    .analytics-iframe{
        width: 100%
    }
}

.CarroselItemWrapper{
    margin: 10px;
}

.switchTitle{
    font-size: 20px;
    font-weight: 600;
    color: #65b5e4;
    width: 100%;
    text-align: right;
}

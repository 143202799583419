.content {
    /* padding: '0 50px';
    margin-top: 64; */
    margin: '16px',
  }
  
  .ant-layout.layout {
    min-height: 100vh;
  }
  
  .header.ant-layout-header {
    padding-left: 0;
  }